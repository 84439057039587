<!-- <template>
  <div class="signup">
    <template v-if="isSubmitted">
      <div class="submitted">Thanks! We'll send you a note shortly.</div>
    </template>
    <template v-else>
      <input
        v-model="email"
        :disabled="isSubmitting"
        placeholder="Your Email"
        type="email"
        :class="{error: isSubmitError}"
      />
      <button class="submit" v-if="!isSubmitting" @click="submitEmail">Submit</button>
    </template>
  </div>
</template> -->

<script>
import axios from "axios";
export default {
  data: function() {
    return {
      isSubmitting: false,
      isSubmitted: false,
      isSubmitError: false,
      email: ""
    };
  },
  methods: {
    submitEmail: async function() {
      if (
        !this.email ||
        !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.email
        )
      ) {
        this.isSubmitError = true;
        return;
      }
      this.isSubmitError = false;
      this.isSubmitting = true;
      try {
        await axios.post("/gate/index/footerSignup", {
          email: this.email
        });
      } finally {
        this.isSubmitting = false;
        this.isSubmitted = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.signup {
  display: flex;
  align-items: center;
  height: 40px;
  position: relative;
  input {
    font-size: 16px;
    box-shadow: none;
    color: #929292;
    line-height: 24px;
    flex: 1 0 auto;
    outline: none;
    border: none;
    width: 100%;
    border-bottom: 1px solid #929292;
    &.error {
      border-color: #c2002f;
    }
  }
  div.submitted {
    color: #929292;
    font-size: 14px;
  }
  button.submit {
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    right: 0px;
    color: #929292;
    border: none;
    background: none;
    text-transform: uppercase;
    padding: 0px 5px;
    top: 5px;
    &:hover {
      color: #ccc8c8;
    }
  }
}
</style>